<template>
  <div>
    <!-- <div class="Preloader BackgroundOverlay">
      <img src="./svg/LensExpert___LoadLogo.svg" class="Logo bounceIn animated" alt="">
    </div>
    <div class="OverlayToLoad BackgroundOverlay"></div> -->
    <header class="Header">
      <div class="container-fluid NoPadding">
        <div class="row">
          <div class="col-6 NoPadding HeightHeader_Logo">
            <a href="/">
              <img
                src="./svg/LensExpert___Logo.svg"
                class="LogoMenu"
                alt="Consultor Óptico"
              />
            </a>
          </div>
          <!-- <div class="col-6 NoPadding HeightHeader_Menu">
            <div class="LiMenor"><Locale :isFromPublic="true" />
                        <li class="LiMenor" v-if="user" style="cursor: pointer">
                            <div
                            link-class="d-flex align-items-center"
                            @click="$router.push('/lentes/')"
                            >
                            <feather-icon
                                size="16"
                                color="white"
                                icon="UserIcon"
                                class="mr-50"
                            />
                            </div>
                        </li>
                        <li class="LiMenor" v-if="user" style="cursor: pointer">
                            <div
                            link-class="d-flex align-items-center"
                            @click="logout()"
                            >
                            <feather-icon
                                size="16"
                                color="white"
                                icon="LogOutIcon"
                                class="mr-50"
                            />
                            </div>
                        </li>
                    </div>
          </div> -->
          <div class="col-6 NoPadding text-right HeightHeader_Logo">
            <a
              href="javascript:window.open('', '_self', '').close();"
              class="BtnClose"
              id="FechaModalBeneficios"
            >
              <img
                src="./svg/CloseModal.svg"
                class="BtnCloseImg"
                alt="Fechar"
              />
            </a>
          </div>
        </div>
      </div>
    </header>
    <section class="Menu">
      <div class="container">
        <div class="col-12">
          <div class="row justify-content-center">
            <div class="col-md-2 col-6">
              <a @click="OpenLens('simple-lens')" id="Menu_VisaoSimples">
                <div class="CardImagem">
                  <img
                    src="./svg/Menu/VisaoSimples.svg"
                    class="img-fluid"
                    alt="Lentes de Visão Simples"
                  />
                </div>
                <div class="CardText">
                  <h5 class="TituloCor1">LENTES DE<br />VISÃO SIMPLES</h5>
                </div>
              </a>
            </div>
            <div class="col-md-2 col-6">
              <a @click="OpenLens('progressive-lens')" id="Menu_Progressivas">
                <div class="CardImagem">
                  <img
                    src="./svg/Menu/Progressivas.svg"
                    class="img-fluid"
                    alt="Lentes Progressivas"
                  />
                </div>
                <div class="CardText">
                  <h5 class="TituloCor1">LENTES<br />PROGRESSIVAS</h5>
                </div>
              </a>
            </div>
            <div class="col-md-2 col-6">
              <a @click="OpenLens('photosensitive')" id="Menu_Fotossensiveis">
                <div class="CardImagem">
                  <img
                    src="./svg/Menu/Fotossensiveis.svg"
                    class="img-fluid"
                    alt="Lentes Fotossensíveis"
                  />
                </div>
                <div class="CardText">
                  <h5 class="TituloCor1">LENTES<br />FOTOSSENSÍVEIS</h5>
                </div>
              </a>
            </div>
            <div class="col-md-2 col-6">
              <a @click="OpenLens('anti-glare/1')" id="Menu_Antirreflexo">
                <div class="CardImagem">
                  <img
                    src="./svg/Menu/Antirreflexo.svg"
                    class="img-fluid"
                    alt="Tratamento Antirreflexo"
                  />
                </div>
                <div class="CardText">
                  <h5 class="TituloCor1">TRATAMENTO<br />ANTIRREFLEXO</h5>
                </div>
              </a>
            </div>
            <div class="col-md-2 col-6">
              <a @click="OpenLens('polarized-lens/1')" id="Menu_Polarizadas">
                <div class="CardImagem">
                  <img
                    src="./svg/Menu/Polarizadas.svg"
                    class="img-fluid"
                    alt="Tratamento Polarizadas"
                  />
                </div>
                <div class="CardText">
                  <h5 class="TituloCor1">LENTES<br />POLARIZADAS</h5>
                </div>
              </a>
            </div>
            <div class="col-md-2 col-6">
              <a @click="OpenLens('occupational-lens')" id="Menu_Ocupacionais">
                <div class="CardImagem">
                  <img
                    src="./svg/Menu/Ocupacionais.svg"
                    class="img-fluid"
                    alt="Lentes Ocupacionais"
                  />
                </div>
                <div class="CardText">
                  <h5 class="TituloCor1">LENTES<br />OCUPACIONAIS</h5>
                </div>
              </a>
            </div>
            <div class="col-md-2 col-6">
              <a @click="OpenLens('sports-lens')" id="Menu_Esportivas">
                <div class="CardImagem">
                  <img
                    src="./svg/Menu/Esportivas.svg"
                    class="img-fluid"
                    alt="Lentes Esportivas"
                  />
                </div>
                <div class="CardText">
                  <h5 class="TituloCor1">LENTES<br />ESPORTIVAS</h5>
                </div>
              </a>
            </div>
            <div class="col-md-2 col-6">
              <a @click="OpenLens('thickness')" id="Menu_Espessuras">
                <div class="CardImagem">
                  <img
                    src="./svg/Menu/Espessuras.svg"
                    class="img-fluid"
                    alt="Espessuras de Lentes"
                  />
                </div>
                <div class="CardText">
                  <h5 class="TituloCor1">ESPESSURAS<br />DE LENTES</h5>
                </div>
              </a>
            </div>
            <div class="col-md-2 col-6">
              <a @click="OpenLens('blue-filter')" id="Menu_FiltroAzul">
                <div class="CardImagem">
                  <img
                    src="./svg/Menu/FiltroAzul.svg"
                    class="img-fluid"
                    alt="Filtro Azul"
                  />
                </div>
                <div class="CardText">
                  <h5 class="TituloCor1">FILTRO<br />AZUL</h5>
                </div>
              </a>
            </div>
            <!-- <div class="col-md-2 col-6 ">
              <a href="javascript:void(0)" id="Menu_Calculos">
                <div class="CardImagem">
                  <img
                    src="./svg/Menu/Calculos.svg"
                    class="img-fluid"
                    alt="Cálculos Ópticos"
                  />
                </div>
                <div class="CardText">
                  <h5 class="TituloCor1">CÁLCULOS<br />ÓPTICOS</h5>
                </div>
              </a>
            </div> -->
            <!-- <div class="col-md-2 col-6 ">
              <a href="javascript:void(0)" id="Menu_TomadorDeMedidas">
                <div class="CardImagem">
                  <img
                    src="./svg/Menu/Dnp.svg"
                    class="img-fluid"
                    alt="Medidas Online"
                  />
                </div>
                <div class="CardText">
                  <h5 class="TituloCor1">TOMADOR<br />DE MEDIDAS</h5>
                </div>
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import Locale from "@/@core/layouts/components/app-navbar/components/Locale.vue"
// import * as script from "@/views/menu/js/Scripts.js"
// import * as script_menu from "@/views/menu/js/Scripts_Menu.js"
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    // Locale

    BRow,
    BCol,
  },
  methods: {
    OpenLens(route) {
      this.$router.push(`/lens/${route}`)
    },
  },
}
</script>

<style>
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');
@import './css/Style.css';
@import './css/MyStyle.css';
@import './css/MyLogin.css';
@import './css/MyColors.css';
@import './css/MyMenu.css';
@import './css/MyMenuInterno.css';
@import './css/MyNav.css';
@import './css/plugins/animate.css';
@import './css/plugins/hamburger.css';
/* @import "./css/plugins/owl.carousel.min.css"; */
@import './css/plugins/owl.theme.default.min.css';

.BtnClose {
  position: absolute;
  width: 70px;
  height: 70px;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 20px 0 0;
}
.BtnCloseImg {
  width: 35px !important;
  height: auto !important;
}
</style>
